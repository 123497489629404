<template>
	<div ref="containerEl" class="parallax">
		<div ref="contentEl" class="parallax__content">
			<slot />
		</div>
	</div>
</template>

<script lang="ts" setup>
	import gsap from 'gsap'

	const props = defineProps<{
		axis?: 'x' | 'y'
		markers?: boolean
	}>()

	const containerEl = ref<HTMLElement | null>(null)
	const contentEl = ref<HTMLElement | null>(null)

	let tl: GSAPTimeline | null = null

	useResizeObserver(containerEl, reset)

	onMounted(() => {
		if (!containerEl.value || !contentEl.value) return

		tl = gsap.timeline({
			scrollTrigger: {
				trigger: containerEl.value,
				scrub: true,
				pin: false,
				markers: props.markers ?? false,
			},
		})

		tl.fromTo(
			contentEl.value,
			{
				scale: 1.2,
				xPercent: props.axis === 'x' ? -20 : 0,
				yPercent: props.axis !== 'x' ? -20 : 0,
			},
			{
				scale: 1.2,
				xPercent: props.axis === 'x' ? 20 : 0,
				yPercent: props.axis !== 'x' ? 20 : 0,
				ease: 'none',
			},
		)
	})

	function reset() {
		tl?.scrollTrigger?.refresh()
	}
</script>

<style lang="scss">
	.parallax {
		overflow: hidden;

		&__content {
			width: 100%;
			height: 100%;
			transform: scale(1.2);
			transform-origin: center;
		}
	}
</style>
